export const handleRuntimeChunkError = () => {
    window.addEventListener('unhandledrejection', (event) => {
      if (isRuntimeChunkError(event.reason)) {
        console.error('⚠️ Runtime chunk load failed:', event.reason);
        attemptChunkReload();
      }
    });
   };
   // Detect runtime chunk errors
   const isRuntimeChunkError = (error) => {
    return error?.message && /Loading chunk [\d]+ failed/.test(error.message);
   };
   // Retry logic for runtime chunks
   const attemptChunkReload = (retries = 2, delay = 2000) => {
    let attempt = 0;
    const reload = () => {
      if (attempt < retries) {
        console.warn(`🔄 Retrying runtime chunk... (${attempt + 1}/${retries})`);
        setTimeout(() => {
          attempt++;
          window.location.reload(true); // Force reload without cache
        }, delay);
      } else {
        console.error('❌ Max retries reached. Reloading page.');
        window.location.reload(); // Final reload if retries are exhausted
      }
    };
    reload();
   };